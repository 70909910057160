<template>
	<div
		:class="[
			'c-block-list-spot-box-container-custom',
			'c-block-list-spot-box-container-custom--wide',
			{
				'bg-sand py-lg -mx-layout-margin px-layout-margin':
					template != 'FrontPage',
			},
		]"
	>
		<!-- Single Spotbox -->
		<div v-if="computedItems.length == 1" class="w-12col flex">
			<NuxtLinkExt
				:to="computedItems[0].link[0].url"
				:class="[
					'c-block-list-spot-box-container-custom__single',
					'hidden >=1024:flex flex-1 group',
					'overflow-hidden rounded-lg bg-white',
				]"
			>
				<div
					:class="[
						'flex-1 py-xl px-lg',
						'flex flex-col justify-between',
					]"
				>
					<div>
						<div
							class="text-h8 uppercase mb-sm"
							v-text="computedItems[0].label"
						></div>

						<BaseH1 tag="h3" v-text="computedItems[0].title" />
						<div
							class="mt-sm"
							v-html="computedItems[0].teaser"
						></div>
					</div>

					<BaseButton
						:aria-label="
							'Gå til' + computedItems[0].title ||
							computedItems[0].link[0].url
						"
						class="w-fit"
					>
						<template #icon>
							<SvgArrow />
						</template>
					</BaseButton>
				</div>

				<div
					:style="`aspect-ratio: ${aspectRatio}`"
					class="w-6col flex-shrink-0 overflow-hidden"
				>
					<UmbracoImageExt
						v-if="
							computedItems[0].image &&
							computedItems[0].image.cropUrl
						"
						:widths="[320, 480, 640, 480 * 2, 640 * 2]"
						:alt="computedItems[0].image.altText"
						:source-url="computedItems[0].image.cropUrl"
						:source-width="computedItems[0].image.width"
						:source-height="computedItems[0].image.height"
						:aspect-ratio="aspectRatio"
						:class="[
							'c-block-list-spot-box-container-custom__single-image',
							'duration-500 ease-smooth-out',
							'transform group-hover:scale-110',
						]"
					/>

					<CookieInformationBlocker
						v-if="
							computedItems[0].videoPicker &&
							computedItems[0].videoPicker.provider
						"
						:categories="
							computedItems[0].videoPicker.cookieCategories
						"
						class="w-full h-full"
						simple
					>
						<VideoPlayer
							class="w-full h-full"
							:picker="computedItems[0].videoPicker"
						/>
					</CookieInformationBlocker>
				</div>
			</NuxtLinkExt>

			<ContentCard
				v-for="(item, index) in computedItems"
				:key="`item-${index}`"
				:image="{ ...(item.image || {}), aspectRatio }"
				:video="{ ...(item.videoPicker || {}), aspectRatio }"
				:to="item.link[0].url"
				:name="item.title"
				size="small"
				class=">=1024:hidden flex-1"
				light
			>
				<template #before>
					<span v-text="item.label"></span>
				</template>

				<template #default>
					<span v-html="item.teaser"></span>
				</template>
			</ContentCard>
		</div>

		<!-- Multiple spotboxes -->
		<div
			v-else-if="computedItems.length"
			:class="[
				'flex flex-col >=1024:flex-row',
				'gap-x-layout-gutter gap-y-md w-12col',
			]"
		>
			<ContentCard
				v-for="(item, index) in computedItems"
				:key="`item-${index}`"
				:image="{ ...(item.image || {}), aspectRatio }"
				:video="{ ...(item.videoPicker || {}), aspectRatio }"
				:to="item.link[0].url"
				:name="item.title"
				size="small"
				class="flex-1"
				light
			>
				<template #before>
					<span v-text="item.label"></span>
				</template>

				<template #default>
					<span v-html="item.teaser"></span>
				</template>
			</ContentCard>
		</div>
	</div>
</template>

<script>
import { mapState } from 'vuex';

import VideoPlayer from '~/citi-baseline/components/VideoPlayer/VideoPlayer';
import CookieInformationBlocker from '~/components/shared/CookieInformationBlockerCustom';
import ContentCard from '~/components/shared/ContentCard';
import UmbracoImageExt from '~/components/shared/UmbracoImageExt';
import SvgArrow from '~/assets/svgs/arrow-icon.svg?inline';

export default {
	name: 'BlockListSpotBoxContainerCustom',

	components: {
		VideoPlayer,
		CookieInformationBlocker,
		ContentCard,
		UmbracoImageExt,
		SvgArrow,
	},

	inheritAttrs: false,

	props: {
		items: {
			type: Array,
			default: () => [],
		},
	},

	data() {
		return {
			isMobile: false,
		};
	},

	computed: {
		...mapState({
			template: (state) => state.template,
		}),

		aspectRatio() {
			return !this.isMobile
				? [640 / 427, 640 / 278, 416 / 278][this.items.length - 1]
				: 343 / 229;
		},

		computedItems() {
			return this.items
				.filter((item) => item?.link?.[0])
				.map((item) => ({
					...item,
					videoPicker: item.video?.content?.video
						? {
								cookieCategories: [
									'marketing',
									'functional',
									'statistic',
								].filter((c) => item.video?.content?.[c]),

								details: {
									...(item.video?.content?.video?.details ??
										{}),
									embed: item.video?.content?.video?.embed
										?.html,
								},

								provider: {
									alias: item.video?.alias
										?.replace('Block', '')
										.toLowerCase(),
								},
						  }
						: false,
				}));
		},
	},

	mounted() {
		window.addEventListener('resize', this.onResize);
		this.onResize();
	},

	beforeDestroy() {
		window.removeEventListener('resize', this.onResize);
	},

	methods: {
		onResize() {
			this.isMobile = window.innerWidth < 768;
		},
	},
};
</script>
